export interface ILocaleItem {
    zh: string
    en: string
    ja: string
    ko: string
    vi: string
}

const locales0 = {
    'homepage': {
        en: 'Homepage',
        zh: '主页',
        ja: 'ホームページ',
        ko: '홈페이지',
        vi: 'Trang chủ',
    },
    'overview': {
        en: 'Overview',
        zh: '概览',
        ja: '概要',
        ko: '개요',
        vi: 'Tổng quan',
    },
    'user': {
        en: 'User',
        zh: '用户',
        ja: 'ユーザー',
        ko: '사용자',
        vi: 'Người dùng',
    },
    'creator': {
        en: 'Creator',
        zh: '创建者',
        ja: 'クリエーター',
        ko: '제작자',
        vi: 'Tác giả',
    },
    'created_at': {
        en: 'Created At',
        zh: '创建时间',
        ja: '作成日時',
        ko: '생성 날짜',
        vi: 'Ngày tạo',
    },
    'build_at': {
        en: 'Build At',
        zh: '编译时间',
        ja: 'ビルド日時',
        ko: '빌드 날짜',
        vi: 'Ngày xây dựng',
    },
    'user group': {
        en: 'User Group',
        zh: '用户组',
        ja: 'ユーザーグループ',
        ko: '사용자 그룹',
        vi: 'Nhóm người dùng',
    },
    'kube_config': {
        en: 'Kubernetes Configuration',
        zh: 'Kubernetes Configuration',
        ja: 'Kubernetes Configuration',
        ko: 'Kubernetes Configuration',
        vi: 'Kubernetes Configuration',
    },
    'config': {
        en: 'Configuration',
        zh: '配置',
        ja: '構成',
        ko: '구성',
        vi: 'Cấu hình',
    },
    'role': {
        en: 'Role',
        zh: '角色',
        ja: 'ロール',
        ko: '역할',
        vi: 'Vai trò',
    },
    'developer': {
        en: 'Developer',
        zh: '开发者',
        ja: '開発者',
        ko: '개발자',
        vi: 'Nhà phát triển',
    },
    'guest': {
        en: 'Guest',
        zh: '访客',
        ja: 'ゲスト',
        ko: '게스트',
        vi: 'Khách',
    },
    'admin': {
        en: 'Admin',
        zh: '管理员',
        ja: '管理者',
        ko: '관리자',
        vi: 'Quản trị viên',
    },
    'create': {
        en: 'Create',
        zh: '创建',
        ja: '作成',
        ko: '생성',
        vi: 'Tạo',
    },
    'submit': {
        en: 'Submit',
        zh: '提交',
        ja: '提出',
        ko: '제출',
        vi: 'Gửi',
    },
    'name': {
        en: 'Name',
        zh: '名称',
        ja: '名前',
        ko: '이름',
        vi: 'Tên',
    },
    'description': {
        en: 'Description',
        zh: '描述',
        ja: '説明',
        ko: '설명',
        vi: 'Mô tả',
    },
    'member': {
        en: 'Member',
        zh: '成员',
        ja: 'メンバー',
        ko: '멤버',
        vi: 'Thành viên',
    },
    'create sth': {
        en: 'Create {{0}}',
        zh: '创建{{0}}',
        ja: '{{0}}を作成',
        ko: '{{0}}을 생성',
        vi: 'Tạo {{0}}',
    },
    'select sth': {
        en: 'Select {{0}}',
        zh: '选择{{0}}',
        ja: '{{0}}を選択',
        ko: '{{0}}을 선택',
        vi: 'Chọn {{0}}',
    },
    'organization': {
        en: 'Organization',
        zh: '组织',
        ja: '組織',
        ko: '조직',
        vi: 'Tổ chức',
    },
    'cluster': {
        en: 'Cluster',
        zh: '集群',
        ja: 'クラスター',
        ko: '클러스터',
        vi: 'Hệ thống',
    },
    'deployment': {
        en: 'Deployment',
        zh: '部署',
        ja: 'デプロイ',
        ko: '배포',
        vi: 'Cấp phát',
    },
    'deployment revision': {
        en: 'Deployment Revision',
        zh: '部署版本',
        ja: 'デプロイリビジョン',
        ko: '배포 리비전',
        vi: 'Phiên bản cấp phát',
    },
    'revision': {
        en: 'Revision',
        zh: '版本',
        ja: 'リビジョン',
        ko: '리비전',
        vi: 'Phiên bản',
    },
    'rollback': {
        en: 'Rollback',
        zh: '回滚',
        ja: 'ロールバック',
        ko: '롤백',
        vi: 'Lùi lại',
    },
    'bento': {
        en: 'Bento',
        zh: 'Bento',
        ja: 'Bento',
        ko: 'Bento',
        vi: 'Bento',
    },
    'bento version': {
        zh: 'Bento 版本',
        en: 'Bento Version',
        ja: 'Bento バージョン',
        ko: 'Bento 버전',
        vi: 'Phiên bản Bento',
    },
    'register': {
        en: 'Register',
        zh: '注册',
        ja: '登録',
        ko: '등록',
        vi: 'Đăng ký',
    },
    'login': {
        en: 'Login',
        zh: '登录',
        ja: 'ログイン',
        ko: '로그인',
        vi: 'Đăng nhập',
    },
    'logout': {
        en: 'Logout',
        zh: '登出',
        ja: 'ログアウト',
        ko: '로그아웃',
        vi: 'Đăng xuất',
    },
    'sth required': {
        zh: '需要填写{{0}}',
        en: '{{0}} was required',
        ja: '{{0}}が必要です',
        ko: '{{0}}이 필요합니다',
        vi: '{{0}} đã được yêu cầu',
    },
    'no data': {
        zh: '暂无数据',
        en: 'No Data',
        ja: 'データがありません',
        ko: '데이터가 없습니다',
        vi: 'Không có dữ liệu',
    },
    'latest version': {
        zh: '最新版',
        en: 'Latest Version',
        ja: '最新バージョン',
        ko: '최신 버전',
        vi: 'Phiên bản mới nhất',
    },
    'version': {
        zh: '版本',
        en: 'Version',
        ja: 'バージョン',
        ko: '버전',
        vi: 'Phiên bản',
    },
    'status': {
        zh: '状态',
        en: 'Status',
        ja: 'ステータス',
        ko: '상태',
        vi: 'Trạng thái',
    },
    'status name': {
        zh: '状态名称',
        en: 'Status Name',
        ja: 'ステータス名',
        ko: '상태 이름',
        vi: 'Tên trạng thái',
    },
    'image build status': {
        zh: '镜像编译状态',
        en: 'Image Build Status',
        ja: 'イメージビルドステータス',
        ko: '이미지 빌드 상태',
        vi: 'Tình trạng xây dựng ảnh',
    },
    'user profile': {
        zh: '用户资料',
        en: 'User Profile',
        ja: 'ユーザープロファイル',
        ko: '사용자 프로필',
        vi: 'Hồ sơ người dùng',
    },
    'api token': {
        zh: 'API 令牌',
        en: 'API Token',
        ja: 'API トークン',
        ko: 'API 토큰',
        vi: 'API mã',
    },
    'production': {
        zh: '正式',
        en: 'Production',
        ja: '製品',
        ko: '생산',
        vi: 'Sản phẩm',
    },
    'stable': {
        zh: '稳定',
        en: 'Stable',
        ja: '安定',
        ko: '안정',
        vi: 'Đảm bảo',
    },
    'canary': {
        zh: '金丝雀',
        en: 'Canary',
        ja: 'キャニー',
        ko: '캐나다',
        vi: 'Canary',
    },
    'canary rules': {
        zh: '金丝雀规则',
        en: 'Canary Rules',
        ja: 'キャニールール',
        ko: '캐나다 규칙',
        vi: 'Quy tắc Canary',
    },
    'weight': {
        zh: '权重',
        en: 'Weight',
        ja: 'ウェイト',
        ko: '무게',
        vi: 'Trọng lượng',
    },
    'header': {
        zh: 'Header',
        en: 'Header',
        ja: 'Header',
        ko: 'Header',
        vi: 'Header',
    },
    'cookie': {
        zh: 'Cookie',
        en: 'Cookie',
        ja: 'Cookie',
        ko: 'Cookie',
        vi: 'Cookie',
    },
    'header value': {
        zh: 'Header 值',
        en: 'Header value',
        ja: 'Header 値',
        ko: 'Header 값',
        vi: 'Giá trị Header',
    },
    'add app deployment canary rule': {
        zh: '增加灰度发布规则',
        en: 'Add app deployment gray rule',
        ja: 'アプリデプロイ灰度ルールを追加',
        ko: '앱 배포 회색 규칙 추가',
        vi: 'Thêm quy tắc bảo trì ứng dụng',
    },
    'create canary deploy': {
        zh: '创建灰度发布',
        en: 'Create gray deploy',
        ja: '灰度デプロイを作成',
        ko: '회색 배포 생성',
        vi: 'Tạo bảo trì ứng dụng',
    },
    'create deploy': {
        zh: '创建部署',
        en: 'Create deploy',
        ja: 'デプロイを作成',
        ko: '배포 생성',
        vi: 'Tạo bản cập nhật',
    },
    'force': {
        zh: '强制',
        en: 'Force',
        ja: '強制',
        ko: '강제',
        vi: 'Force',
    },
    'deployment detail': {
        zh: '部署详情',
        en: 'Deployment Detail',
        ja: 'デプロイ詳細',
        ko: '배포 세부 사항',
        vi: 'Chi tiết bản cập nhật',
    },
    'visibility': {
        zh: '可见性',
        en: 'Visibility',
        ja: '可視性',
        ko: '가시성',
        vi: 'Hiển thị',
    },
    'non-deployed': {
        zh: '未部署',
        en: 'Non Deployed',
        ja: '未デプロイ',
        ko: '배포되지 않음',
        vi: 'Chưa cập nhật',
    },
    'unhealthy': {
        zh: '不健康',
        en: 'Unhealthy',
        ja: '不健康',
        ko: '안정적이지 않음',
        vi: 'Không ổn định',
    },
    'failed': {
        zh: '失败',
        en: 'Failed',
        ja: '失敗',
        ko: '실패',
        vi: 'Thất bại',
    },
    'deploying': {
        zh: '部署中',
        en: 'Deploying',
        ja: 'デプロイ中',
        ko: '배포 중',
        vi: 'Đang cập nhật',
    },
    'running': {
        zh: '运行中',
        en: 'Running',
        ja: '実行中',
        ko: '실행 중',
        vi: 'Đang chạy',
    },
    'unknown': {
        zh: '未知',
        en: 'Unknown',
        ja: '不明',
        ko: '알 수 없음',
        vi: 'Không rõ',
    },
    'replicas': {
        zh: '副本',
        en: 'Replicas',
        ja: '副本',
        ko: '복제',
        vi: 'Phiên bản',
    },
    'type': {
        zh: '类型',
        en: 'Type',
        ja: 'タイプ',
        ko: '유형',
        vi: 'Loại',
    },
    'view log': {
        zh: '查看日志',
        en: 'View Log',
        ja: 'ログを見る',
        ko: '로그 보기',
        vi: 'Xem nhật ký',
    },
    'view terminal history': {
        zh: '查看终端操作记录',
        en: 'View Terminal History',
        ja: '終端操作履歴を見る',
        ko: '터미널 기록 보기',
        vi: 'Xem lịch sử của máy tính đầu cuối',
    },
    'download log': {
        zh: '下载日志',
        en: 'Download Log',
        ja: 'ログをダウンロード',
        ko: '로그 다운로드',
        vi: 'Tải nhật ký',
    },
    'log forward': {
        zh: '日志正序',
        en: 'Log Forward',
        ja: 'ログ正序',
        ko: '로그 정렬',
        vi: 'Nhật ký tăng dần',
    },
    'stop scroll': {
        zh: '停止滚动',
        en: 'Stop Scroll',
        ja: 'スクロールを停止',
        ko: '스크롤 중지',
        vi: 'Dừng cuộn',
    },
    'scroll': {
        zh: '滚动',
        en: 'Scroll',
        ja: 'スクロール',
        ko: '스크롤',
        vi: 'Cuộn',
    },
    'all pods': {
        zh: '所有的 Pod',
        en: 'All Pods',
        ja: 'すべての Pod',
        ko: '모든 Pod',
        vi: 'Tất cả Pod',
    },
    'lines': {
        zh: '行数',
        en: 'Lines',
        ja: '行数',
        ko: '줄',
        vi: 'Dòng',
    },
    'realtime': {
        zh: '实时',
        en: 'Realtime',
        ja: 'リアルタイム',
        ko: '실시간',
        vi: 'Thời gian thực',
    },
    'max lines': {
        zh: '最大行数',
        en: 'Max Lines',
        ja: '最大行数',
        ko: '최대 줄',
        vi: 'Dòng tối đa',
    },
    'advanced': {
        zh: '高级',
        en: 'Advanced',
        ja: '高度',
        ko: '고급',
        vi: 'Nâng cao',
    },
    'cpu limits': {
        zh: 'CPU 资源限制',
        en: 'CPU Resources Limits',
        ja: 'CPU リソースリミット',
        ko: 'CPU 자원 제한',
        vi: 'Giới hạn CPU',
    },
    'gpu limits': {
        zh: 'GPU 资源限制',
        en: 'GPU Resources Limits',
        ja: 'GPU リソースリミット',
        ko: 'GPU 자원 제한',
        vi: 'Giới hạn GPU',
    },
    'gpu requests': {
        zh: 'GPU 资源请求',
        en: 'GPU Resources Requests',
        ja: 'GPU リソースリクエスト',
        ko: 'GPU 자원 요청',
        vi: 'Yêu cầu GPU',
    },
    'cpu': {
        zh: 'CPU',
        en: 'CPU',
        ja: 'CPU',
        ko: 'CPU',
        vi: 'CPU',
    },
    'gpu': {
        zh: '显卡',
        en: 'GPU',
        ja: 'GPU',
        ko: 'GPU',
        vi: 'GPU',
    },
    'memory': {
        zh: '内存',
        en: 'Memory',
        ja: 'メモリ',
        ko: '메모리',
        vi: 'Bộ nhớ',
    },
    'memory limits': {
        zh: '内存资源限制',
        en: 'Memory Resources Limits',
        ja: 'メモリリソースリミット',
        ko: '메모리 자원 제한',
        vi: 'Giới hạn bộ nhớ',
    },
    'cpu requests': {
        zh: 'CPU 资源请求',
        en: 'CPU Resources Requests',
        ja: 'CPU リソースリクエスト',
        ko: 'CPU 자원 요청',
        vi: 'Yêu cầu CPU',
    },
    'memory requests': {
        zh: '内存资源请求',
        en: 'Memory Resources Requests',
        ja: 'メモリ リソースリクエスト',
        ko: '메모리 자원 요청',
        vi: 'Yêu cầu bộ nhớ',
    },
    'add': {
        zh: '添加',
        en: 'Add',
        ja: '追加',
        ko: '추가',
        vi: 'Thêm',
    },
    'action': {
        zh: '行为',
        en: 'Action',
        ja: 'アクション',
        ko: '행동',
        vi: 'Hành động',
    },
    'Terminating': {
        zh: '结束中',
        en: 'Terminating',
        ja: '終了中',
        ko: '종료 중',
        vi: 'Đang kết thúc',
    },
    'ContainerCreating': {
        zh: '创建中',
        en: 'Creating',
        ja: '作成中',
        ko: '생성 중',
        vi: 'Đang tạo',
    },
    'pending': {
        zh: '等待中',
        en: 'Pending',
        ja: '保留中',
        ko: '대기 중',
        vi: 'Đang chờ',
    },
    'building': {
        zh: '编译中',
        en: 'Building',
        ja: 'ビルド中',
        ko: '빌드 중',
        vi: 'Đang xây dựng',
    },
    'Pending': {
        zh: '等待中',
        en: 'Pending',
        ja: '保留中',
        ko: '대기 중',
        vi: 'Đang chờ',
    },
    'Running': {
        zh: '运行中',
        en: 'Running',
        ja: '実行中',
        ko: '실행 중',
        vi: 'Đang chạy',
    },
    'success': {
        zh: '成功',
        en: 'Succeeded',
        ja: '成功',
        ko: '성공',
        vi: 'Thành công',
    },
    'Succeeded': {
        zh: '成功',
        en: 'Succeeded',
        ja: '成功',
        ko: '성공',
        vi: 'Thành công',
    },
    'Failed': {
        zh: '失败',
        en: 'Failed',
        ja: '失敗',
        ko: '실패',
        vi: 'Thất bại',
    },
    'Unknown': {
        zh: '未知',
        en: 'Unknown',
        ja: '不明',
        ko: '알 수 없음',
        vi: 'Không rõ',
    },
    'start time': {
        zh: '启动时间',
        en: 'Start Time',
        ja: '開始時間',
        ko: '시작 시간',
        vi: 'Thời gian bắt đầu',
    },
    'end time': {
        zh: '结束时间',
        en: 'End Time',
        ja: '終了時間',
        ko: '종료 시간',
        vi: 'Thời gian kết thúc',
    },
    'terminal': {
        zh: '终端',
        en: 'Terminal',
        ja: '終端',
        ko: '종료',
        vi: 'Kết thúc',
    },
    'operation': {
        en: 'Operation',
        zh: '操作',
        ja: '操作',
        ko: '작업',
        vi: 'Hoạt động',
    },
    'pod': {
        zh: 'Pod',
        en: 'Pod',
        ja: 'Pod',
        ko: 'Pod',
        vi: 'Pod',
    },
    'container': {
        zh: '容器',
        en: 'Container',
        ja: 'コンテナ',
        ko: '컨테이너',
        vi: 'Thực thể',
    },
    'playback operation': {
        zh: '回放操作',
        en: 'Playback Operation',
        ja: 'プレイバック操作',
        ko: '돌아가기 작업',
        vi: 'Lặp lại hoạt động',
    },
    'no event': {
        zh: '暂无事件',
        en: 'No Event',
        ja: 'イベントなし',
        ko: '이벤트 없음',
        vi: 'Không có sự kiện',
    },
    'event': {
        zh: '事件',
        en: 'Event',
        ja: 'イベント',
        ko: '이벤트',
        vi: 'Sự kiện',
    },
    'events': {
        zh: '事件列表',
        en: 'Events',
        ja: 'イベントリスト',
        ko: '이벤트 목록',
        vi: 'Danh sách sự kiện',
    },
    'node': {
        zh: '节点',
        en: 'Node',
        ja: 'ノード',
        ko: '노드',
        vi: 'Nút',
    },
    'settings': {
        zh: '设置',
        en: 'Settings',
        ja: '設定',
        ko: '설정',
        vi: 'Cài đặt',
    },
    'regexp': {
        zh: '正则表达式',
        en: 'Regexp',
        ja: '正規表現',
        ko: '정규 표현식',
        vi: 'Biểu thức chính quy',
    },
    'contains': {
        zh: '包含',
        en: 'Contains',
        ja: '含む',
        ko: '포함',
        vi: 'Bao gồm',
    },
    'not contains': {
        zh: '不包含',
        en: 'Not Contains',
        ja: '含まない',
        ko: '포함 않음',
        vi: 'Không bao gồm',
    },
    'clear': {
        zh: '清空',
        en: 'Clear',
        ja: 'クリア',
        ko: '지우기',
        vi: 'Xóa',
    },
    'and': {
        zh: '且',
        en: 'And',
        ja: '且',
        ko: '그리고',
        vi: 'Và',
    },
    'add filter condition': {
        zh: '添加过滤条件',
        en: 'Add Filter Condition',
        ja: 'フィルタ条件を追加',
        ko: '필터 조건 추가',
        vi: 'Thêm điều kiện lọc',
    },
    'match': {
        zh: '匹配',
        en: 'Match',
        ja: 'マッチ',
        ko: '일치',
        vi: 'Khớp',
    },
    'not match': {
        zh: '不匹配',
        en: 'Not Match',
        ja: 'マッチしない',
        ko: '일치하지 않음',
        vi: 'Không khớp',
    },
    'advanced search': {
        zh: '高级搜索',
        en: 'Advanced Search',
        ja: '高度な検索',
        ko: '고급 검색',
        vi: 'Tìm kiếm nâng cao',
    },
    'search': {
        zh: '搜索',
        en: 'Search',
        ja: '検索',
        ko: '검색',
        vi: 'Tìm kiếm',
    },
    'please enter keywords': {
        zh: '请输入关键词',
        en: 'Please enter keywords',
        ja: 'キーワードを入力してください',
        ko: '키워드를 입력하십시오',
        vi: 'Vui lòng nhập từ khóa',
    },
    'yatai component': {
        zh: 'Yatai 组件',
        en: 'Yatai Component',
        ja: 'Yatai コンポーネント',
        ko: 'Yatai 구성 요소',
        vi: 'Yatai thành phần',
    },
    'deployed': {
        zh: '部署完毕',
        en: 'Deployed',
        ja: 'デプロイ済み',
        ko: '배포 완료',
        vi: 'Đã được cấp phát',
    },
    'uninstalled': {
        zh: '卸载完毕',
        en: 'Uninstalled',
        ja: 'アンインストール済み',
        ko: '제거 완료',
        vi: 'Đã gỡ cài đặt',
    },
    'superseded': {
        zh: '已废弃',
        en: 'Superseded',
        ja: '廃止済み',
        ko: '사법 제한',
        vi: 'Đã bị thay thế',
    },
    'uninstalling': {
        zh: '卸载中',
        en: 'Uninstalling',
        ja: 'アンインストール中',
        ko: '제거 중',
        vi: 'Đang gỡ cài đặt',
    },
    'pending-install': {
        zh: '等待安装',
        en: 'Pending Install',
        ja: 'インストール保留中',
        ko: '설치 대기 중',
        vi: 'Đang chờ cài đặt',
    },
    'pending-upgrade': {
        zh: '等待升级',
        en: 'Pending Upgrade',
        ja: 'アップグレード保留中',
        ko: '업그레이드 대기 중',
        vi: 'Đang chờ nâng cấp',
    },
    'pending-rollback': {
        zh: '等待回滚',
        en: 'Pending Rollback',
        ja: 'リバック保留中',
        ko: '롤백 대기 중',
        vi: 'Đang chờ lùi lại',
    },
    'logging': {
        zh: '日志',
        en: 'Logging',
        ja: 'ログ',
        ko: '로그',
        vi: 'Nhật ký',
    },
    'monitoring': {
        zh: '监控',
        en: 'Monitoring',
        ja: 'モニタリング',
        ko: '감시',
        vi: 'Theo dõi',
    },
    'please install yatai component first': {
        zh: '请先安装 Yatai {{0}} 组件',
        en: 'Please install yatai component {{0}} first',
        ja: 'Yatai コンポーネント {{0}} を先にインストールしてください',
        ko: 'Yatai 구성 요소 {{0}} 먼저 설치하십시오',
        vi: 'Vui lòng cài đặt yatai thành phần {{0}} trước',
    },
    'monitor': {
        zh: '监控器',
        en: 'Monitor',
        ja: 'モニター',
        ko: '감시자',
        vi: 'Bộ đo',
    },
    'upgrade': {
        zh: '升级',
        en: 'Upgrade',
        ja: 'アップグレード',
        ko: '업그레이드',
        vi: 'Nâng cấp',
    },
    'upgrade to sth': {
        zh: '升级到 {{0}}',
        en: 'Upgrade to {{0}}',
        ja: '{{0}} にアップグレード',
        ko: '{{0}} 업그레이드',
        vi: 'Nâng cấp đến {{0}}',
    },
    'do you want to upgrade yatai component sth to some version': {
        zh: '你想把 Yatai 组件 {{0}} 升级到 {{1}} 版本吗？',
        en: 'Do you want to upgrade yatai component {{0}} to {{1}} version?',
        ja: 'Yatai コンポーネント {{0}} を {{1}} バージョンにアップグレードしますか？',
        ko: 'Yatai 구성 요소 {{0}} 버전을 {{1}} 버전으로 업그레이드하시겠습니까?',
        vi: 'Bạn có muốn nâng cấp yatai thành phần {{0}} đến phiên bản {{1}} không?',
    },
    'do you want to delete yatai component sth': {
        zh: '你想删除 Yatai 组件 {{0}} 吗？',
        en: 'Do you want to delete yatai component {{0}}?',
        ja: 'Yatai コンポーネント {{0}} を削除しますか？',
        ko: 'Yatai 구성 요소 {{0}} 삭제하시겠습니까?',
        vi: 'Bạn có muốn xóa yatai thành phần {{0}} không?',
    },
    'cancel': {
        zh: '取消',
        en: 'Cancel',
        ja: 'キャンセル',
        ko: '취소',
        vi: 'Hủy',
    },
    'ok': {
        zh: '确定',
        en: 'Okay',
        ja: 'OK',
        ko: '확인',
        vi: 'Đồng ý',
    },
    'delete': {
        zh: '删除',
        en: 'Delete',
        ja: '削除',
        ko: '삭제',
        vi: 'Xóa',
    },
    'kube resources': {
        zh: 'K8S 资源列表',
        en: 'K8S Resources',
        ja: 'K8S リソース一覧',
        ko: 'K8S 자원 목록',
        vi: 'K8S tài nguyên',
    },
    'helm release name': {
        zh: 'HELM 发布名称',
        en: 'HELM Release Name',
        ja: 'HELM リリース名',
        ko: 'HELM 발표 이름',
        vi: 'HELM phát hành tên',
    },
    'helm chart name': {
        zh: 'HELM Chart 名称',
        en: 'HELM Chart Name',
        ja: 'HELM Chart 名',
        ko: 'HELM Chart 이름',
        vi: 'HELM Chart tên',
    },
    'helm chart description': {
        zh: 'HELM Chart 详情',
        en: 'HELM Chart Description',
        ja: 'HELM Chart 詳細',
        ko: 'HELM Chart 세부 정보',
        vi: 'HELM Chart chi tiết',
    },
    'model': {
        zh: '模型',
        en: 'Model',
        ja: 'モデル',
        ko: '모델',
        vi: 'Mô hình',
    },
    'model versions': {
        zh: '模型版本',
        en: 'Model Versions',
        ja: 'モデルバージョン',
        ko: '모델 버전',
        vi: 'Phiên bản mô hình',
    },
    'name or email': {
        zh: '用户名或者邮箱地址',
        en: 'Name or Email',
        ja: 'ユーザー名またはメールアドレス',
        ko: '이름 또는 이메일 주소',
        vi: 'Tên người dùng hoặc địa chỉ email',
    },
    'password': {
        zh: '密码',
        en: 'Password',
        ja: 'パスワード',
        ko: '암호',
        vi: 'Mật khẩu',
    },
    'update': {
        zh: '更新',
        en: 'Update',
        ja: '更新',
        ko: '업데이트',
        vi: 'Cập nhật',
    },
    'update sth': {
        zh: '更新 {{0}}',
        en: 'Update {{0}}',
        ja: '{{0}} を更新',
        ko: '{{0}} 업데이트',
        vi: 'Cập nhật {{0}}',
    },
    'deployment target': {
        zh: '部署对象',
        en: 'Deployment Target',
        ja: 'デプロイ対象',
        ko: '배포 대상',
        vi: 'Điểm đặt',
    },
    'add sth': {
        zh: '添加{{0}}',
        en: 'Add {{0}}',
        ja: '{{0}}を追加',
        ko: '{{0}} 추가',
        vi: 'Thêm {{0}}',
    },
    'api': {
        zh: 'API',
        en: 'API',
        ja: 'API',
        ko: 'API',
        vi: 'API',
    },
    'read_organization': {
        zh: '读取组织',
        en: 'Read Organization',
        ja: '組織を読み込む',
        ko: '조직 읽기',
        vi: 'Đọc tổ chức',
    },
    'write_organization': {
        zh: '写入组织',
        en: 'Write Organization',
        ja: '組織を書き込む',
        ko: '조직 쓰기',
        vi: 'Viết tổ chức',
    },
    'read_cluster': {
        zh: '读取集群',
        en: 'Read Cluster',
        ja: 'クラスタを読み込む',
        ko: '클러스터 읽기',
        vi: 'Đọc hội',
    },
    'write_cluster': {
        zh: '写入集群',
        en: 'Write Cluster',
        ja: 'クラスタを書き込む',
        ko: '클러스터 쓰기',
        vi: 'Viết hội',
    },
    'scope api description': {
        en: 'Grants complete read/write access to the API, including all organizations and clusters and deployments.',
        zh: '授予对 API 的完全读/写权限，包括所有组织和集群。',
        ja: 'API の完全読/書き権限を与えます。',
        ko: 'API 에 전체 읽기/쓰기 권한을 부여합니다. 전체 클러스터 및 조직을 포함합니다.',
        vi: 'Cấp quyền đọc/ghi đầy đủ cho API, bao gồm tất cả tổ chức và hội.',
    },
    'scope read organization description': {
        en: 'Grants read access to the organization, including all clusters and deployments.',
        zh: '授予对组织的读权限，包括所有集群和部署。',
        ja: '組織の読み権限を与えます。',
        ko: '조직 읽기 권한을 부여합니다. 클러스터 및 배포를 포함합니다.',
        vi: 'Cấp quyền đọc tổ chức, bao gồm tất cả hội và các phiên bản.',
    },
    'scope write organization description': {
        en: 'Grants read-write access to the organization, including all clusters and deployments.',
        zh: '授予对组织的读/写权限，包括所有集群和部署。',
        ja: '組織の読/書き権限を与えます。',
        ko: '조직 읽기/쓰기 권한을 부여합니다. 클러스터 및 배포를 포함합니다.',
        vi: 'Cấp quyền đọc/ghi tổ chức, bao gồm tất cả hội và các phiên bản.',
    },
    'scope read cluster description': {
        en: 'Grants read access to the cluster, including all deployments.',
        zh: '授予对集群的读权限，包括所有部署。',
        ja: 'クラスタの読み権限を与えます。',
        ko: '클러스터 읽기 권한을 부여합니다. 배포를 포함합니다.',
        vi: 'Cấp quyền đọc hội, bao gồm tất cả phiên bản.',
    },
    'scope write cluster description': {
        en: 'Grants read-write access to the cluster, including all deployments.',
        zh: '授予对集群的读/写权限，包括所有部署。',
        ja: 'クラスタの読/書き権限を与えます。',
        ko: '클러스터 읽기/쓰기 권한을 부여합니다. 배포를 포함합니다.',
        vi: 'Cấp quyền đọc/ghi hội, bao gồm tất cả phiên bản.',
    },
    'scopes': {
        zh: '权限',
        en: 'Scopes',
        ja: '権限',
        ko: '권한',
        vi: 'Phạm vi',
    },
    'expired_at': {
        zh: '过期时间',
        en: 'Expired At',
        ja: '有効期限',
        ko: '만료 시간',
        vi: 'Hết hạn',
    },
    'last_used_at': {
        zh: '最后使用时间',
        en: 'Last Used At',
        ja: '最終使用日時',
        ko: '마지막 사용 시간',
        vi: 'Thời gian sử dụng cuối cùng',
    },
    'api token only show once time tips': {
        zh: 'API Token 只会显示一次，请保存好且不要泄露此 Token',
        en: 'API Token will only show once, please save it and do not disclose it.',
        ja: 'API Token は一度だけ表示されます。保存してください。',
        ko: 'API 토큰은 한 번만 표시됩니다. 저장하고 안전하게 비밀번호를 알려주세요.',
        vi: 'API Token sẽ chỉ hiển thị một lần, hãy lưu và không bao giờ nói chuyện với nó.',
    },
    'copy': {
        zh: '复制',
        en: 'Copy',
        ja: 'コピー',
        ko: '복사',
        vi: 'Sao chép',
    },
    'copied to clipboard': {
        zh: '已复制到剪贴板',
        en: 'Copied to clipboard',
        ja: 'クリップボードにコピーしました',
        ko: '클립보드에 복사되었습니다.',
        vi: 'Đã sao chép vào clipboard',
    },
    'newest': {
        zh: '最新',
        en: 'Newest',
        ja: '最新',
        ko: '최신',
        vi: 'Mới nhất',
    },
    'oldest': {
        zh: '最旧',
        en: 'Oldest',
        ja: '最古',
        ko: '오래된',
        vi: 'Cũ nhất',
    },
    'sort': {
        zh: '排序',
        en: 'Sort',
        ja: 'ソート',
        ko: '정렬',
        vi: 'Sắp xếp',
    },
    'last updater': {
        zh: '最后更新者',
        en: 'Last Updater',
        ja: '最終更新者',
        ko: '마지막 업데이트',
        vi: 'Người cập nhật cuối cùng',
    },
    'updated_at': {
        zh: '更新时间',
        en: 'Updated At',
        ja: '更新日時',
        ko: '업데이트 시간',
        vi: 'Thời gian cập nhật',
    },
    'newest update': {
        zh: '最新更新',
        en: 'Newest Update',
        ja: '最新更新',
        ko: '최신 업데이트',
        vi: 'Cập nhật mới nhất',
    },
    'oldest update': {
        zh: '最旧更新',
        en: 'Oldest Update',
        ja: '最古更新',
        ko: '오래된 업데이트',
        vi: 'Cập nhật cũ nhất',
    },
    'newest build': {
        zh: '最新构建',
        en: 'Newest Build',
        ja: '最新ビルド',
        ko: '최신 빌드',
        vi: 'Bản xây dựng mới nhất',
    },
    'oldest build': {
        zh: '最旧构建',
        en: 'Oldest Build',
        ja: '最古ビルド',
        ko: '오래된 빌드',
        vi: 'Bản xây dựng cũ nhất',
    },
    'filter by sth': {
        zh: '按照 {{0}} 过滤',
        en: 'Filter by {{0}}',
        ja: '{{0}} をフィルター',
        ko: '{{0}} 필터링',
        vi: 'Lọc theo {{0}}',
    },
    'clear search keyword, filters and sorts': {
        zh: '清除搜索关键词、过滤器和排序规则',
        en: 'Clear search keyword, filters and sorts',
        ja: '検索キーワード、フィルターとソートをクリア',
        ko: '검색 키워드, 필터링 및 정렬 규칙을 지웁니다.',
        vi: 'Xóa từ khóa tìm kiếm, bộ lọc và quy tắc sắp xếp',
    },
    'filters': {
        zh: '过滤器',
        en: 'Filters',
        ja: 'フィルター',
        ko: '필터링',
        vi: 'Bộ lọc',
    },
    'the deployments I created': {
        zh: '我创建的部署',
        en: 'The deployments I created',
        ja: '私が作成した部署',
        ko: '내가 만든 배포',
        vi: 'Các phiên bản đã tạo bởi tôi',
    },
    'my last updated deployments': {
        zh: '我最后更新的部署',
        en: 'My last updated deployments',
        ja: 'マイ・最終更新デプロイ',
        ko: '내 마지막 업데이트 배포',
        vi: 'Các phiên bản đã cập nhật cuối cùng của tôi',
    },
    'bento repository': {
        zh: 'Bento 仓库',
        en: 'Bento Repository',
        ja: 'Bento リポジトリ',
        ko: 'Bento 저장소',
        vi: 'Kho hàng Bento',
    },
    'model repository': {
        zh: '模型仓库',
        en: 'Model Repository',
        ja: 'モデルリポジトリ',
        ko: '모델 저장소',
        vi: 'Kho hàng mô hình',
    },
    'the models I created': {
        zh: '我创建的 Model',
        en: 'The models I created',
        ja: '私が作成した Model',
        ko: '내가 만든 모델',
        vi: 'Các mô hình đã tạo bởi tôi',
    },
    'the bentos I created': {
        zh: '我创建的 Bento',
        en: 'The bentos I created',
        ja: '私が作成した Bento',
        ko: '내가 만든 Bento',
        vi: 'Các bento đã tạo bởi tôi',
    },
    'the bento versions I Created': {
        zh: '我创建的 Bento 版本',
        en: 'The bento versions I Created',
        ja: '私が作成した Bento バージョン',
        ko: '내가 만든 Bento 버전',
        vi: 'Các phiên bản bento đã tạo bởi tôi',
    },
    'my last updated bentos': {
        zh: '我最后更新的 Bento',
        en: 'My last updated bentos',
        ja: 'マイ・最終更新 Bento',
        ko: '내 마지막 업데이트 Bento',
        vi: 'Các bento đã cập nhật cuối cùng của tôi',
    },
    'terminate': {
        zh: '终止',
        en: 'Terminate',
        ja: '終了',
        ko: '종료',
        vi: 'Kết thúc',
    },
    'terminate sth': {
        zh: '终止{{0}}',
        en: 'Terminate {{0}}',
        ja: '{{0}}を終了',
        ko: '{{0}} 종료',
        vi: 'Kết thúc {{0}}',
    },
    'delete sth': {
        zh: '删除{{0}}',
        en: 'Delete {{0}}',
        ja: '{{0}}を削除',
        ko: '{{0}} 삭제',
        vi: 'Xóa {{0}}',
    },
    'restore': {
        zh: '恢复',
        en: 'Restore',
        ja: '復元',
        ko: '복원',
        vi: 'Khôi phục',
    },
    'terminating': {
        zh: '终止中',
        en: 'Terminating',
        ja: '終了中',
        ko: '종료 중',
        vi: 'Đang kết thúc',
    },
    'terminated': {
        zh: '已终止',
        en: 'Terminated',
        ja: '終了しました',
        ko: '종료됨',
        vi: 'Đã kết thúc',
    },
    'terminate deployment tips': {
        en: 'This will terminate this running deployment and the endpoint will not no longer be accessible. Terminated deployment can be restarted and all deployment revision history are preserved.',
        zh: '这将终止正在运行的部署，并且该端点不再可访问。终止的部署可以重新启动，并且部署历史记录将保留。',
        ja: 'これは、このランニング・デプロイを終了し、エンドポイントはもうアクセスできなくなります。終了したデプロイは再起動でき、すべてのデプロイのリビジョン履歴は保存されます。',
        ko: '이것은 실행 중인 배포를 종료하고 엔드 포인트는 더 이상 접근할 수 없습니다. 종료된 배포는 다시 시작할 수 있으며 모든 배포 리비전 기록은 유지됩니다.',
        vi: 'Điều này sẽ ngừng các giao diện đang chạy và địa chỉ IP sẽ không còn truy cập. Giao diện đã ngừng sẽ không thể khởi động lại và tất cả lịch sử bản ghi giao diện được giữ lại.',
    },
    'delete deployment tips prefix': {
        en: 'Warning: ',
        zh: '警告：',
        ja: '警告：',
        ko: '경고: ',
        vi: 'Cảnh báo: ',
    },
    'delete deployment tips highlight': {
        en: 'deleting deployment will also remove all its revision history and this action is not recoverable.',
        zh: '删除部署将同时删除部署的所有历史版本，该操作不可恢复。',
        ja: 'デプロイの削除により、全てのリビジョン履歴が削除され、この操作は復元できません。',
        ko: '배포 삭제는 배포의 모든 역사를 삭제하며 이 작업은 복구할 수 없습니다.',
        vi: 'Xóa giao diện sẽ cùng xóa tất cả lịch sử bản ghi của giao diện này và hành động này không thể khôi phục.',
    },
    'delete deployment tips suffix': {
        en: 'You can however re-create a deployment with the same name and config.',
        zh: '但是，你可以通过同样的名字和配置重新创建部署。',
        ja: 'しかし、同じ名前と設定を使用してデプロイを再作成することができます。',
        ko: '만약에는 배포를 같은 이름과 구성으로 다시 만들 수 있습니다.',
        vi: 'Tuy nhiên, bạn có thể tạo lại giao diện với cùng tên và cấu hình.',
    },
    'double check to be continued tips prefix': {
        en: 'To continue, input the {{0}} name ',
        zh: '输入{{0}}名 ',
        ja: '{{0}}名 ',
        ko: '{{0}} 이름을 입력하십시오.',
        vi: 'Để tiếp tục, nhập tên {{0}}',
    },
    'double check to be continued tips suffix': {
        en: ' below:',
        zh: ' 以继续：',
        ja: ' を入力して続行：',
        ko: ' 아래에 입력하십시오:',
        vi: ' để tiếp tục:',
    },
    'some days': {
        en: '{{0}} days',
        zh: '{{0}} 天',
        ja: '{{0}} 日',
        ko: '{{0}} 일',
        vi: '{{0}} ngày',
    },
    'no expiration': {
        en: 'No expiration',
        zh: '不过期',
        ja: '期限なし',
        ko: '만료 없음',
        vi: 'Không hết hạn',
    },
    'custom...': {
        en: 'Custom...',
        zh: '自定义...',
        ja: 'カスタム...',
        ko: '사용자 정의...',
        vi: 'Tùy chỉnh...',
    },
    'Created At': {
        en: 'Created At',
        zh: '创建于',
        ja: '作成場所',
        ko: '생성 일자',
        vi: 'Ngày tạo',
    },
    'Time since Creation': {
        en: 'Time since Creation',
        zh: '自创建以来的时间',
        ja: '創作からの時間',
        ko: '생성 후 시간',
        vi: 'Thời gian từ khi tạo',
    },
    'the token will expire on sth': {
        en: 'The token will expire on {{0}}',
        zh: '令牌将在 {{0}} 过期',
        ja: 'トークンは {{0}} に期限が切れます',
        ko: '토큰은 {{0}} 만료됩니다.',
        vi: 'Mã token sẽ hết hạn vào {{0}}',
    },
    'the token will never expire!': {
        en: 'The token will never expire!',
        zh: '此令牌永不过期！',
        ja: 'トークンは永久に期限がありません！',
        ko: '토큰은 영구적으로 만료되지 않습니다!',
        vi: 'Mã token sẽ không bao giờ hết hạn!',
    },
    'are you sure to delete this api token?': {
        en: 'Are you sure to delete this api token?',
        zh: '你确定要删除此 API 令牌吗？',
        ja: 'この API トークンを削除してもよろしいですか？',
        ko: '이 API 토큰을 삭제하시겠습니까?',
        vi: 'Bạn có chắc chắn muốn xóa mã token này không?',
    },
    'copy command to login yatai': {
        en: 'Authenticate with Yatai by running the following command:',
        zh: '通过运行以下命令与 Yatai 进行验证:',
        ja: '次のコマンドを実行して、Yataiで認証します:',
        ko: '다음 명령을 실행하여 Yatai로 인증하십시오:',
        vi: 'Để xác thực với Yatai, hãy chạy câu lệnh sau:',
    },
    'rerun': {
        en: 'Rerun',
        zh: '重新运行',
        ja: '再実行',
        ko: '다시 실행',
        vi: 'Chạy lại',
    },
    'n_bentos': {
        en: '{{0}} Bento(s)',
        zh: '{{0}} 个 Bento',
        ja: '{{0}} 個 Bento',
        ko: '{{0}} 개 Bento',
        vi: '{{0}} bộ Bento',
    },
    'n_versions': {
        en: '{{0}} Version(s)',
        zh: '{{0}} 个版本',
        ja: '{{0}} 個バージョン',
        ko: '{{0}} 개 버전',
        vi: '{{0}} phiên bản',
    },
    'n_deployments': {
        en: '{{0}} Deployment(s)',
        zh: '{{0}} 个部署',
        ja: '{{0}} 個デプロイ',
        ko: '{{0}} 개 배포',
        vi: '{{0}} phiên bản',
    },
    'largest': {
        en: 'Largest',
        zh: '最大',
        ja: '最大',
        ko: '최대',
        vi: 'Lớn nhất',
    },
    'smallest': {
        en: 'Smallest',
        zh: '最小',
        ja: '最小',
        ko: '최소',
        vi: 'Nhỏ nhất',
    },
    'latest deployments': {
        en: 'Latest deployments',
        zh: '最新部署',
        ja: '最新のデプロイ',
        ko: '최신 배포',
        vi: 'Phiên bản mới nhất',
    },
    'latest versions': {
        en: 'Latest versions',
        zh: '最新版本',
        ja: '最新のバージョン',
        ko: '최신 버전',
        vi: 'Phiên bản mới nhất',
    },
    'module': {
        en: 'Module',
        zh: '模块',
        ja: 'モジュール',
        ko: '모듈',
        vi: 'Mô đun',
    },
    'label': {
        en: 'Label',
        zh: '标签',
        ja: 'ラベル',
        ko: '라벨',
        vi: 'Nhãn',
    },
    'context': {
        en: 'Context',
        zh: '上下文',
        ja: 'コンテキスト',
        ko: '컨텍스트',
        vi: 'Nội dung',
    },
    'download': {
        en: 'Download',
        zh: '下载',
        ja: 'ダウンロード',
        ko: '다운로드',
        vi: 'Tải về',
    },
    'key': {
        en: 'Key',
        zh: '键',
        ja: 'キー',
        ko: '키',
        vi: 'Khóa',
    },
    'value': {
        en: 'Value',
        zh: '值',
        ja: '値',
        ko: '값',
        vi: 'Giá trị',
    },
    'recent activities': {
        en: 'Recent Activities',
        zh: '最近活动',
        ja: '最近の活動',
        ko: '최근 활동',
        vi: 'Hoạt động gần đây',
    },
    'pushed': {
        en: 'Pushed',
        zh: '推送',
        ja: 'プッシュ',
        ko: '배포',
        vi: 'Đã được đẩy',
    },
    'active deployments': {
        en: 'Active Deployments',
        zh: '活动部署',
        ja: 'アクティブなデプロイ',
        ko: '활성 배포',
        vi: 'Phiên bản đang hoạt động',
    },
    'revisions': {
        en: 'Revisions',
        zh: '版本列表',
        ja: 'リビジョン',
        ko: '리비전',
        vi: 'Phiên bản',
    },
    'labels': {
        en: 'Labels',
        zh: '标签列表',
        ja: 'ラベル',
        ko: '라벨',
        vi: 'Nhãn',
    },
    'models': {
        en: 'Models',
        zh: '模型列表',
        ja: 'モデル',
        ko: '모델',
        vi: 'Mô hình',
    },
    'bentos': {
        en: 'Bentos',
        zh: 'Bentos',
        ja: 'Bentos',
        ko: 'Bentos',
        vi: 'Bentos',
    },
    'deployments': {
        en: 'Deployments',
        zh: '部署列表',
        ja: 'デプロイ',
        ko: '배포',
        vi: 'Phiên bản',
    },
    'model repositories': {
        en: 'Model Repositories',
        zh: '模型仓库列表',
        ja: 'モデルリポジトリ',
        ko: '모델 저장소',
        vi: 'Kho chứa mô hình',
    },
    'bento repositories': {
        en: 'Bento Repositories',
        zh: 'Bento 仓库列表',
        ja: 'Bento リポジトリ',
        ko: 'Bento 저장소',
        vi: 'Kho chứa Bento',
    },
    'users': {
        en: 'Users',
        zh: '用户列表',
        ja: 'ユーザー',
        ko: '사용자',
        vi: 'Người dùng',
    },
    'members': {
        en: 'Members',
        zh: '成员列表',
        ja: 'メンバー',
        ko: '멤버',
        vi: 'Thành viên',
    },
    'organizations': {
        en: 'Organizations',
        zh: '组织列表',
        ja: '組織',
        ko: '조직',
        vi: 'Tổ chức',
    },
    'clusters': {
        en: 'Clusters',
        zh: '集群列表',
        ja: 'クラスター',
        ko: '클러스터',
        vi: 'Nhóm',
    },
    'yatai components': {
        en: 'Yatai Components',
        zh: 'Yatai 组件列表',
        ja: 'Yatai コンポーネント',
        ko: 'Yatai 구성 요소',
        vi: 'Yatai thành phần',
    },
    'deployment targets': {
        en: 'Deployment Targets',
        zh: '部署目标列表',
        ja: 'デプロイターゲット',
        ko: '배포 대상',
        vi: 'Mục tiêu phân phối',
    },
    'api tokens': {
        en: 'API Tokens',
        zh: 'API 令牌列表',
        ja: 'API トークン',
        ko: 'API 토큰',
        vi: 'API mã',
    },
    'documentation': {
        en: 'Documentation',
        zh: '文档',
        ja: 'ドキュメント',
        ko: '문서',
        vi: 'Tài liệu',
    },
    'release notes': {
        en: 'Release Notes',
        zh: '发布说明',
        ja: 'リリースノート',
        ko: '릴리즈 노트',
        vi: 'Ghi chú phát hành',
    },
    'blog posts': {
        en: 'Blog Posts',
        zh: '博客文章',
        ja: 'ブログ記事',
        ko: '블로그 게시물',
        vi: 'Bài viết blog',
    },
    'contact': {
        en: 'Contact',
        zh: '联系',
        ja: 'お問い合わせ',
        ko: '연락',
        vi: 'Liên hệ',
    },
    'docs': {
        en: 'Docs',
        zh: '文档',
        ja: 'ドキュメント',
        ko: '문서',
        vi: 'Tài liệu',
    },
    'community': {
        en: 'Community',
        zh: '社区',
        ja: 'コミュニティ',
        ko: '커뮤니티',
        vi: 'Cộng đồng',
    },
    'the events I created': {
        en: 'The events I created',
        zh: '我创建的事件',
        ja: '私が作成したイベント',
        ko: '내가 만든 이벤트',
        vi: 'Sự kiện tôi tạo',
    },
    'oldest create': {
        en: 'Oldest create',
        zh: '最早创建',
        ja: '最初に作成',
        ko: '최초 만들기',
        vi: 'Tạo nhất',
    },
    'newest create': {
        en: 'Newest create',
        zh: '最新创建',
        ja: '最新に作成',
        ko: '최신 만들기',
        vi: 'Tạo mới nhất',
    },
    'resource type': {
        en: 'Resource Type',
        zh: '资源类型',
        ja: 'リソースタイプ',
        ko: '자원 유형',
        vi: 'Loại tài nguyên',
    },
    'operation name': {
        en: 'Operation Name',
        zh: '操作名称',
        ja: '操作名',
        ko: '작업 이름',
        vi: 'Tên thao tác',
    },
    'the default kube namespace for deployments': {
        en: 'The default k8s namespace for deployments',
        zh: '部署的默认 k8s 命名空间',
        ja: 'デプロイのデフォルトの k8s ネームスペース',
        ko: '배포에 대한 기본 k8s 네임스페이스',
        vi: 'Không gian k8s mặc định cho phân phối',
    },
    'new deployment': {
        en: 'New Deployment',
        zh: '新建部署',
        ja: '新規デプロイ',
        ko: '새 배포',
        vi: 'Phân phối mới',
    },
    'update deployment': {
        en: 'Update Deployment',
        zh: '更新部署',
        ja: 'デプロイの更新',
        ko: '배포 업데이트',
        vi: 'Cập nhật phân phối',
    },
    'deployment name': {
        en: 'Deployment Name',
        zh: '部署名称',
        ja: 'デプロイ名',
        ko: '배포 이름',
        vi: 'Tên phân phối',
    },
    'select bento': {
        en: 'Select Bento',
        zh: '选择 Bento',
        ja: 'ベントを選択',
        ko: '베토를 선택하십시오',
        vi: 'Chọn Bento',
    },
    'configurations': {
        en: 'Configurations',
        zh: '配置',
        ja: '設定',
        ko: '구성',
        vi: 'Cấu hình',
    },
    'min': {
        en: 'Min',
        zh: '最小',
        ja: '最小',
        ko: '최소',
        vi: 'Tối thiểu',
    },
    'max': {
        en: 'Max',
        zh: '最大',
        ja: '最大',
        ko: '최대',
        vi: 'Tối đa',
    },
    'number of replicas': {
        en: 'Number of replicas',
        zh: '副本数',
        ja: 'レプリカ数',
        ko: '복제 수',
        vi: 'Số lượng bản sao',
    },
    'resource per replicas': {
        en: 'Resource per replicas',
        zh: '副本资源',
        ja: 'レプリカ資源',
        ko: '복제 자원',
        vi: 'Tài nguyên cho mỗi bản sao',
    },
    'environment variables': {
        en: 'Environment variables',
        zh: '环境变量',
        ja: '環境変数',
        ko: '환경 변수',
        vi: 'Biến toàn cục',
    },
    'no deployment found': {
        en: 'No deployment found',
        zh: '没有找到部署',
        ja: 'デプロイが見つかりません',
        ko: '배포를 찾을 수 없습니다',
        vi: 'Không tìm thấy phân phối',
    },
    'edit': {
        en: 'Edit',
        zh: '编辑',
        ja: '編集',
        ko: '편집',
        vi: 'Chỉnh sửa',
    },
    'n deployments': {
        en: '{{0}} deployments',
        zh: '{{0}} 个部署',
        ja: '{{0}} 个のデプロイ',
        ko: '{{0}} 개의 배포',
        vi: '{{0}} phân phối',
    },
    'n bentos': {
        en: '{{0}} Bentos',
        zh: '{{0}} 个 Bento',
        ja: '{{0}} 个 Bento',
        ko: '{{0}} Bento',
        vi: '{{0}} Bento',
    },
    'Follow to [BentoML quickstart guide] to create your first Bento. prefix': {
        en: 'Follow to ',
        zh: '查看 ',
        ja: '',
        ko: '',
        vi: 'Theo dõi ',
    },
    'BentoML quickstart guide': {
        en: 'BentoML quickstart guide',
        zh: 'BentoML 快速入門指南',
        ja: 'BentoML クイックスタートガイド',
        ko: 'BentoML 퀵스타트 가이드',
        vi: 'BentoML hướng dẫn nhanh',
    },
    'Follow to [BentoML quickstart guide] to create your first Bento. suffix': {
        en: ' to create your first Bento.',
        zh: ' 创建你的第一个 Bento。',
        ja: ' を参照して、あなたの最初のベントを作成します。',
        ko: '를 따라서 첫 번째 베토를 만듭니다.',
        vi: ' để tạo ra bản gốc của bạn đầu tiên.',
    },
    'Create an [API-token] and login your BentoML CLI. prefix': {
        en: 'Create an ',
        zh: '创建一个 ',
        ja: '',
        ko: '',
        vi: 'Tạo một ',
    },
    'API-token': {
        en: 'API-token',
        zh: 'API-token',
        ja: 'APIトークン',
        ko: 'API 토큰',
        vi: 'API-token',
    },
    'Create an [API-token] and login your BentoML CLI. suffix': {
        en: ' and login your BentoML CLI.',
        zh: ' 并登录你的 BentoML CLI。',
        ja: ' とログインします。',
        ko: ' 및 베토ML CLI를 로그인합니다.',
        vi: ' và đăng nhập vào CLI của BentoML.',
    },
    'Push new Bento to Yatai with the `bentoml push` CLI command. prefix': {
        en: 'Push new Bento to Yatai with the ',
        zh: '使用 ',
        ja: '',
        ko: '',
        vi: 'Đăng bản gốc mới vào Yatai bằng lệnh CLI ',
    },
    'Push new Bento to Yatai with the `bentoml push` CLI command. suffix': {
        en: ' CLI command.',
        zh: ' 命令将新的 Bento 推送到 Yatai。',
        ja: ' コマンドで新しいベントを Yatai にプッシュします。',
        ko: ' 명령어로 새 베토를 Yatai에 밀어 넣습니다.',
        vi: '.',
    },
    'Follow to [BentoML quickstart guide] to create your first Model. prefix': {
        en: 'Follow to ',
        zh: '查看 ',
        ja: '',
        ko: '',
        vi: 'Theo dõi ',
    },
    'Follow to [BentoML quickstart guide] to create your first Model. suffix': {
        en: ' to create your first Model.',
        zh: ' 创建你的第一个模型。',
        ja: ' を参照して、あなたの最初のモデルを作成します。',
        ko: '를 따라서 첫 번째 모델을 만듭니다.',
        vi: ' để tạo ra bản gốc của bạn đầu tiên.',
    },
    'Push new Model to Yatai with the `bentoml models push` CLI command. prefix': {
        en: 'Push new Model to Yatai with the ',
        zh: '使用 ',
        ja: '',
        ko: '',
        vi: 'Đăng bản gốc mới vào Yatai bằng lệnh CLI ',
    },
    'Push new Model to Yatai with the `bentoml models push` CLI command. suffix': {
        en: ' CLI command.',
        zh: ' 命令将新的模型推送到 Yatai。',
        ja: ' コマンドで新しいモデルを Yatai にプッシュします。',
        ko: ' 명령어로 새 모델을 Yatai에 밀어 넣습니다.',
        vi: '.',
    },
    'kube namespace': {
        en: 'Kubernetes Namespace',
        zh: 'Kubernetes 命名空间',
        ja: 'Kubernetes の名前空間',
        ko: 'Kubernetes 네임스페이스',
        vi: 'Kubernetes Namespace',
    },
    'first_name': {
        en: 'First Name',
        zh: '名',
        ja: '名',
        ko: '이름',
        vi: 'Tên',
    },
    'last_name': {
        en: 'Last Name',
        zh: '姓',
        ja: '姓',
        ko: '성',
        vi: 'Họ',
    },
    'email': {
        en: 'email',
        zh: '邮箱',
        ja: 'メールアドレス',
        ko: '이메일',
        vi: 'email',
    },
    'assign user to role': {
        en: 'Assign user to role',
        zh: '分配用户到角色',
        ja: 'ユーザーをロールに割り当てる',
        ko: '사용자를 역할에 할당하십시오',
        vi: 'Gán người dùng vào vai trò',
    },
    'create new user': {
        en: 'Create new user',
        zh: '创建新用户',
        ja: '新しいユーザーを作成する',
        ko: '새 사용자 만들기',
        vi: 'Tạo người dùng mới',
    },
    'assign user roles': {
        en: 'Assign user roles',
        zh: '分配用户角色',
        ja: 'ユーザーのロールを割り当てる',
        ko: '사용자 역할 할당',
        vi: 'Gán vai trò cho người dùng',
    },
    'edit user role': {
        en: 'Edit user role',
        zh: '编辑用户角色',
        ja: 'ユーザーのロールを編集',
        ko: '사용자 역할 편집',
        vi: 'Chỉnh sửa vai trò người dùng',
    },
    'deactivate': {
        en: 'Deactivate',
        zh: '停用',
        ja: '停止',
        ko: '중지',
        vi: 'Tắt',
    },
    'active': {
        en: 'Active',
        zh: '激活',
        ja: '有効',
        ko: '활성',
        vi: 'Hoạt động',
    },
    'deactivated': {
        en: 'Deactivated',
        zh: '已停用',
        ja: '停止',
        ko: '중지됨',
        vi: 'Đã tắt',
    },
    'assigned new role': {
        en: 'Assigned new role',
        zh: '分配新角色',
        ja: '新しいロールを割り当てる',
        ko: '새 역할 할당',
        vi: 'Gán vai trò mới',
    },
    'created new user': {
        en: 'Created new user',
        zh: '创建新用户',
        ja: '新しいユーザーを作成',
        ko: '새 사용자 만들기',
        vi: 'Tạo người dùng mới',
    },
    'password changed': {
        en: 'Password changed',
        zh: '密码已更改',
        ja: 'パスワードが変更されました',
        ko: '비밀번호 변경',
        vi: 'Mật khẩu đã được thay đổi',
    },
    'password not match': {
        en: 'Password not match',
        zh: '密码不匹配',
        ja: 'パスワードが一致しません',
        ko: '비밀번호가 일치하지 않습니다',
        vi: 'Mật khẩu không khớp',
    },
    'change password': {
        en: 'Change password',
        zh: '更改密码',
        ja: 'パスワードを変更',
        ko: '비밀번호 변경',
        vi: 'Thay đổi mật khẩu',
    },
    'new password': {
        en: 'New password',
        zh: '新密码',
        ja: '新しいパスワード',
        ko: '새 비밀번호',
        vi: 'Mật khẩu mới',
    },
    'confirm password': {
        en: 'Confirm password',
        zh: '确认密码',
        ja: 'パスワードを確認',
        ko: '비밀번호 확인',
        vi: 'Xác nhận mật khẩu',
    },
    'current password': {
        en: 'Current password',
        zh: '当前密码',
        ja: '現在のパスワード',
        ko: '현재 비밀번호',
        vi: 'Mật khẩu hiện tại',
    },
    'setup initial admin account': {
        en: 'Setup initial admin account',
        zh: '设置初始管理员账户',
        ja: '初期管理者アカウントを設定する',
        ko: '초기 관리자 계정 설정',
        vi: 'Thiết lập tài khoản quản trị đầu tiên',
    },
    'get the initialization token from command': {
        en: 'Get the initialization token from command',
        zh: '从命令中获取初始化令牌',
        ja: 'コマンドから初期化トークンを取得する',
        ko: '명령어에서 초기화 토큰 가져오기',
        vi: 'Lấy mã token khởi tạo từ lệnh',
    },
    'already the latest version': {
        en: 'Already the latest version',
        zh: '已经是最新版本',
        ja: '最新バージョンです',
        ko: '이미 최신 버전입니다',
        vi: 'Đã là phiên bản mới nhất',
    },
    'group': {
        en: 'Group',
        zh: '组',
        ja: 'グループ',
        ko: '그룹',
        vi: 'Nhóm',
    },
    'component': {
        en: 'Component',
        zh: '组件',
        ja: 'コンポーネント',
        ko: '구성 요소',
        vi: 'Thành phần',
    },
    'endpoint public access': {
        en: 'Endpoint public access',
        zh: '端点公开访问',
        ja: 'エンドポイントの公開アクセス',
        ko: '엔드포인트 공개 접근',
        vi: 'Truy cập công khai',
    },
    'cluster internal url': {
        en: 'Cluster internal URL',
        zh: '集群内部 URL',
        ja: 'クラスタ内部 URL',
        ko: '클러스터 내부 URL',
        vi: 'URL nội bộ',
    },
    'accessing endpoint': {
        en: 'Accessing Endpoint',
        zh: '访问端点',
        ja: 'エンドポイントにアクセス',
        ko: '엔드포인트에 접근',
        vi: 'Truy cập',
    },
    'accessing endpoint modal content piece 1': {
        en: 'This deployment is configured with',
        zh: '此部署配置了',
        ja: 'このデプロイメントは',
        ko: '이 배포만큼',
        vi: 'Đã cấu hình với',
    },
    'accessing endpoint modal content piece 2': {
        en: 'which means the endpoint can only be accessed from with-in the cluster.',
        zh: '这意味着端点只能在集群内被访问。',
        ja: 'によってエンドポイントはクラスタ内だけにアクセスできます。',
        ko: '에서만 접근할 수 있습니다.',
        vi: 'đồng nghĩa là endpoint chỉ có thể được truy cập từ trong cluster.',
    },
    'accessing endpoint modal content piece 3': {
        en: 'If you need access to the endpoint for testing and debugging, you will need direct access to the cluster. Assuming you have',
        zh: '如果您需要访问端点以进行测试和调试，您需要直接访问集群。假设您有',
        ja: 'テストやデバッグのためにエンドポイントにアクセスする必要がある場合、クラスタに直接アクセスする必要があります。',
        ko: '테스트 및 디버깅을 위해 엔드포인트에 접근해야 할 경우, 클러스터에 직접 접근해야 합니다. 아래에서',
        vi: 'Nếu bạn cần truy cập endpoint để thực hiện test và debug, bạn sẽ cần truy cập trực tiếp vào cluster. Với tư cách',
    },
    'accessing endpoint modal content piece 4': {
        en: 'installed and configured with the target cluster, you can create a local proxy that forwards requests to the deployment endpoint in cluster with the following command',
        zh: '已安装和配置了目标集群，您可以创建一个本地代理，它用以下命令将请求转发到集群中的部署端点',
        ja: 'インストールと設定されたクラスタにあるデプロイメントのエンドポイントにアクセスするために、以下のコマンドを使用して、クラスタ内のデプロイメントのエンドポイントへのリクエストを転送することができます。',
        ko: '설치 및 설정된 타겟 클러스터에서, 다음 명령어를 사용하여 다음과 같이 로컬 프록시를 만들 수 있습니다',
        vi: 'Bạn có thể tạo proxy để chuyển tiếp yêu cầu đến endpoint trong cluster với các lệnh sau',
    },
    'accessing endpoint modal content piece 5': {
        en: 'Now visit browser at, or send test requests to',
        zh: '现在访问浏览器，或者发送测试请求到',
        ja: '以下のURLまたはテストリクエストを送信してください。',
        ko: '지금 브라우저에서 방문하거나, 다음 요청을 보내십시오',
        vi: 'Bây giờ truy cập trình duyệt tại, hoặc gửi yêu cầu test tới',
    },
    'accessing endpoint modal content piece 6': {
        en: 'to test out the endpoint.',
        zh: '来测试端点。',
        ja: 'エンドポイントをテストしてください。',
        ko: '엔드포인트를 테스트하십시오.',
        vi: 'để thực hiện test endpoint.',
    },
    'enabled': {
        en: 'Enabled',
        zh: '已启用',
        ja: '有効',
        ko: '사용 중',
        vi: 'Đã kích hoạt',
    },
    'disabled': {
        en: 'Disabled',
        zh: '已禁用',
        ja: '無効',
        ko: '사용 중지',
        vi: 'Đã tắt',
    },
    'endpoint public access enable piece 1': {
        en: 'Public endpoint access enabled.',
        zh: '公共端点访问已启用。',
        ja: '公共端点アクセスが有効になりました。',
        ko: '공용 엔드 포인트 접근이 사용 중입니다.',
        vi: 'Truy cập của đầu vào đã được kích hoạt.',
    },
    'warning': {
        en: 'Warning',
        zh: '警告',
        ja: '警告',
        ko: '경고',
        vi: 'Cảnh báo',
    },
    'endpoint public access enable piece 2': {
        en: 'Yatai will create a unique URL for the deployment endpoint, which is publically accessible from outside of the cluster. Related ingress resources will also be created in the target cluster.',
        zh: 'Yatai 将创建一个唯一的 URL 来访问部署端点，该端点将在集群外部可访问。相关的 ingress 资源也将在目标集群中创建。',
        ja: 'Yatai は、クラスタ外部からアクセス可能な公共端点を作成します。',
        ko: 'Yatai 는 배포 엔드 포인트에 접근할 유니크한 URL을 만듭니다. 이 URL은 클러스터 외부에서 접근 가능합니다.',
        vi: 'Yatai sẽ tạo một URL duy nhất để truy cập đầu vào, đó sẽ được truy cập từ bên ngoài của hệ thống. Người dùng sẽ có thể truy cập đến đầu vào này từ bên ngoài hệ thống.',
    },
    'endpoint public access disable piece 1': {
        en: 'Public endpoint access disabled. Yatai will create a Kubernetes Service for the deployment which can only be accessed from within the target cluster. If you have direct access to the cluster, you may use',
        zh: '公共端点访问已禁用。Yatai 将创建一个 Kubernetes 服务来访问部署端点，该端点将在集群内部可访问。如果您有直接访问集群的权限，您可以使用',
        ja: '公共端点アクセスが無効になりました。Yatai は、クラスタ内部からアクセス可能な公共端点を作成します。',
        ko: 'Yatai 는 배포 엔드 포인트에 접근할 유니크한 URL을 만듭니다. 이 URL은 클러스터 안에서만 접근 가능합니다. 만약 클러스터 안에서 접근할 수 있다면',
        vi: 'Truy cập đầu vào đã bị tắt. Yatai sẽ tạo một dịch vụ Kubernetes để truy cập đầu vào, đó sẽ được truy cập từ bên trong của hệ thống. Nếu bạn có truy cập trực tiếp đến hệ thống, bạn có thể sử dụng',
    },
    'endpoint public access disable piece 2': {
        en: 'for testing the endpoint locally.',
        zh: '来测试端点。',
        ja: 'をテストすることができます。',
        ko: '에서 엔드 포인트를 시뮬레이션합니다.',
        vi: 'để kiểm tra đầu vào trực tiếp.',
    },
    'Only the administrator has permission to create deployments, please contact the administrator': {
        en: 'Only the administrator has permission to create deployments, please contact the administrator',
        zh: '只有管理员有权创建部署，请联系管理员',
        ja: '管理者のみがデプロイを作成することができます。管理者にお問い合わせください。',
        ko: '오nly the administrator has permission to create deployments, please contact the administrator',
        vi: 'Chỉ có quyền quản trị viên mới có thể tạo bản đồ, vui lòng liên hệ quản trị viên',
    },
    'Only the administrator has permission to operate deployments, please contact the administrator': {
        en: 'Only the administrator has permission to operate deployments, please contact the administrator',
        zh: '只有管理员有权操作部署，请联系管理员',
        ja: '管理者のみがデプロイを操作することができます。管理者にお問い合わせください。',
        ko: '오nly the administrator has permission to operate deployments, please contact the administrator',
        vi: 'Chỉ có quyền quản trị viên mới có thể thao tác bản đồ, vui lòng liên hệ quản trị viên',
    },
    'resource config': {
        en: 'Resource Config',
        zh: '资源配置',
        ja: 'リソース設定',
        ko: '자원 구성',
        vi: 'Cấu hình nguồn',
    },
}

export const locales: { [key in keyof typeof locales0]: ILocaleItem } = locales0
